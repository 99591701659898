const firebase =
  process.env.VUE_APP_ENVIRONMENT === 'development'
    ? {
        apiKey: 'AIzaSyB8_8D0nfDPDe-h5zaSR5nYugovwnM50to',
        authDomain: 'push-notification-dev-f12e0.firebaseapp.com',
        projectId: 'push-notification-dev-f12e0',
        storageBucket: 'push-notification-dev-f12e0.appspot.com',
        messagingSenderId: '1040692005136',
        appId: '1:1040692005136:web:910386f928509400a2fd95',
        measurementId: 'G-5PGPH1BPEL',
      }
    : {
        apiKey: 'AIzaSyDM4RU1_ayL3vMbfsFqXtCdt_ldrLk57Ys',
        authDomain: 'push-notification-prod-65833.firebaseapp.com',
        projectId: 'push-notification-prod-65833',
        storageBucket: 'push-notification-prod-65833.appspot.com',
        messagingSenderId: '83738540221',
        appId: '1:83738540221:web:2b4089473da0f8b8a21fd4',
        measurementId: 'G-RF1XVXEZLY',
      };

module.exports = firebase;
