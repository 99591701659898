<template>
  <div class="mt-3" id="page-settings">
    <div class="mobile-content">
      <div class="accordion" id="accordionExample" v-show="isLogged">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left txt-primary collapsed no-underline"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                @click="actionGetReceiver"
              >
                Nomor WA Penerima Notifikasi
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <button
                type="button"
                class="btn color-primary float-right"
                v-b-modal.modal-receiver
              >
                <i class="material-icons text-light">add</i>
              </button>
              <div class="table-responsive">
                <b-table
                  class="mt-2 projects table table-striped"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  :isLoading="isLoading"
                  show-empty
                  hover
                  responsive="xl"
                >
                  <template #cell(No)="row">
                    <div style="width: 30px">
                      {{ (currentPage - 1) * perPage + (row.index + 1) }}.
                    </div>
                  </template>

                  <template #cell(name)="row">
                    <div style="width: 200px">
                      {{ row.item?.name ? row.item?.name : '-' }}
                    </div>
                  </template>

                  <template #cell(phone)="row">
                    <div style="width: 200px">
                      {{ row.item?.phone ? row.item?.phone : '-' }}
                    </div>
                  </template>

                  <template #cell(isActive)="row">
                    <div v-if="row.item.isActive">
                      <span class="badge badge-pill bg-success">aktif</span>
                    </div>
                    <div v-else>
                      <span class="badge badge-pill bg-danger"
                        >tidak aktif</span
                      >
                    </div>
                  </template>

                  <template #cell(action)="row">
                    <b-button-group>
                      <b-button
                        variant="info"
                        @click="editReceiver(row.item.id)"
                        data-toggle="modal"
                        data-target="#staticBackdrop"
                        >edit
                      </b-button>
                    </b-button-group>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-dark my-2">
                      <b-spinner
                        class="align-middle"
                        variant="dark"
                        type="grow"
                        label="Loading .."
                      >
                      </b-spinner>
                      Loading...
                    </div>
                  </template>
                </b-table>
              </div>
              <b-row class="float-right mt-3">
                <b-col lg="3">
                  <b-pagination
                    class="custom-pagination"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left txt-primary collapsed no-underline"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                @click="actionGetSettingByModule(moduleMaxNotifDaily)"
              >
                Maksimal Notifikasi Harian
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                  Maksimal {{ maxNotif.setValue }} notifikasi per hari
                  <br />
                  <br />
                  Sisa kuota harian
                  {{
                    parseInt(maxNotif?.setValue) -
                    parseInt(maxNotif?.countValue)
                  }}
                  notifikasi
                  <hr />
                  <b-button
                    class="button-primary block"
                    v-b-modal.modal-maxNotif
                    >edit</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left txt-primary collapsed no-underline"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                @click="actionGetSettingByModule(moduleRangeTime)"
              >
                Rentang Waktu Pengiriman Notifikasi
              </button>
            </h2>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                  Dari Jam {{ rangeTime.setValue }} sampai Jam
                  {{ rangeTime.countValue }}
                  <hr />
                  <b-button
                    class="button-primary block"
                    v-b-modal.modal-rangeTime
                    >edit</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-header" id="headingFour">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left txt-primary collapsed no-underline"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                @click="actionGetSettingByModule(moduleTextNotif)"
              >
                Teks Notifikasi
              </button>
            </h2>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                  {{ textNotif.description }}
                  <hr />
                  <b-button
                    class="button-primary block"
                    v-b-modal.modal-textNotif
                    >edit</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-header" id="headingFour">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left txt-primary collapsed no-underline"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFour"
                @click="actionGetSettingByModule(moduleStatusConnection)"
              >
                Cek Status Koneksi Alat
              </button>
            </h2>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                  <span v-if="statusConnection.setValue">
                    Alat berhasil tersambung dengan jaringan internet
                    <i class="material-icons text-success">check</i>
                  </span>
                  <span v-else>
                    Alat tidak tersambung dengan jaringan internet
                    <i class="material-icons text-danger">close</i>
                  </span>
                  <hr />
                  <b-button
                    class="button-primary block"
                    v-b-modal.modal-statusConnection
                    >edit</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <!-- Modal -->
      <b-modal id="modal-receiver" hide-footer>
        <div class="modal-dialog">
          <b-overlay :show="isLoading" rounded="sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Form Penerima Notifikasi
                </h5>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="name-input">Nama :</label>
                  <input
                    type="text"
                    v-model="form.name"
                    placeholder="nama penerima"
                    class="form-control"
                    id="name-input"
                  />
                </div>
                <div class="form-group">
                  <label for="phone-input">Nomor Handphone :</label>
                  <input
                    type="number"
                    v-model="form.phone"
                    placeholder="nomor handphone penerima"
                    class="form-control"
                    id="phone-input"
                    @input="validatePhoneNumber"
                  />
                </div>
                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    v-model="form.isActive"
                    class="form-check-input mx-1 mt-2"
                  />
                  <label class="form-check-label mx-2" for="status-checkbox"
                    >status</label
                  >
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  style="border-radius: 30px"
                  @click="hideModal"
                >
                  tutup
                </button>
                <button
                  type="button"
                  @click="actionSubmitReceiver"
                  class="btn button-primary"
                >
                  submit
                </button>
              </div>
            </div>
          </b-overlay>
        </div>
      </b-modal>
      <b-modal id="modal-maxNotif" hide-footer>
        <b-overlay :show="isLoading" rounded="sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Form Maksimal Notifikasi</h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="maxNotif-input">Jumlah Maksimal Notifikasi :</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formSetting.setValue"
                  id="maxNotif-input"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="border-radius: 30px"
                @click="hideModal"
              >
                tutup
              </button>
              <button
                type="button"
                @click="actionUpdateSetting"
                class="btn button-primary"
              >
                submit
              </button>
            </div>
          </div>
        </b-overlay>
      </b-modal>
      <b-modal id="modal-rangeTime" hide-footer>
        <b-overlay :show="isLoading" rounded="sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Form Rentang Waktu Notifikasi</h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="dari-jam-input">Dari Jam :</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formSetting.setValue"
                  id="dari-jam-input"
                />
              </div>
              <div class="form-group">
                <label for="sampai-jam-input">Sampai Jam :</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formSetting.countValue"
                  id="sampai-jam-input"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="border-radius: 30px"
                @click="hideModal"
              >
                tutup
              </button>
              <button
                type="button"
                @click="actionUpdateSetting"
                class="btn button-primary"
              >
                submit
              </button>
            </div>
          </div>
        </b-overlay>
      </b-modal>
      <b-modal id="modal-textNotif" hide-footer>
        <b-overlay :show="isLoading" rounded="sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Form Teks Notifikasi</h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="teks-notifikasi-input">Teks Notifikasi</label>
                <textarea
                  class="form-control"
                  v-model="formSetting.description"
                  rows="3"
                  id="teks-notifikasi-input"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="border-radius: 30px"
                @click="hideModal"
              >
                tutup
              </button>
              <button
                type="button"
                @click="actionUpdateSetting"
                class="btn button-primary"
              >
                submit
              </button>
            </div>
          </div>
        </b-overlay>
      </b-modal>
      <b-modal id="modal-statusConnection" hide-footer>
        <b-overlay :show="isLoading" rounded="sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Form Status Koneksi</h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="statusConnection-input">Status Koneksi :</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="formSetting.setValue"
                  id="statusConnection-input"
                  @input="validateStatusConnection"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="border-radius: 30px"
                @click="hideModal"
              >
                tutup
              </button>
              <button
                type="button"
                @click="actionUpdateSetting"
                class="btn button-primary"
              >
                submit
              </button>
            </div>
          </div>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import { moduleSetting } from '../../helper/constants';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebaseConfig from '../../config/firebase';

export default {
  name: 'Setting',
  data() {
    return {
      isLogged: false,
      currentPage: 1,
      perPage: 5,
      fields: [
        { key: 'No', label: 'No.', thStyle: { maxWidth: '15px' } },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'isActive',
          label: 'Status',
        },
        { key: 'action', label: 'Action', thStyle: { maxWidth: '150px' } },
      ],
      moduleMaxNotifDaily: moduleSetting.module_maximal_message_daily,
      moduleRangeTime: moduleSetting.module_range_time,
      moduleTextNotif: moduleSetting.module_text_message,
      moduleStatusConnection: moduleSetting.module_status_connection,
      isAddReceiver: true,
      form: {
        name: '',
        phone: '',
        isActive: true,
      },
      formSetting: {
        setValue: '',
        countValue: '',
        description: '',
        isActive: false,
      },
    };
  },
  created() {
    this.loginPass();
  },
  computed: {
    ...mapState({
      successMessage: (state) => state.receiver.successMessage,
      errorMessage: (state) => state.receiver.errorMessage,
      successMessageSetting: (state) => state.setting.successMessage,
      errorMessageSetting: (state) => state.setting.errorMessage,
      isLoading: (state) => state.receiver.isLoading,
      totalRows: (state) => state.receiver.totalRows,
      items: (state) => state.receiver.items,
      setting: (state) => state.setting.item,
      isLoadingSetting: (state) => state.setting.isLoading,
      isError: (state) => state.setting.isError,
      maxNotif: (state) => state.setting.maxNotif,
      rangeTime: (state) => state.setting.rangeTime,
      textNotif: (state) => state.setting.textNotif,
      statusConnection: (state) => state.setting.statusConnection,
    }),
  },
  watch: {
    currentPage: function () {
      this.actionGetReceiver();
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.actionGetReceiver();
    },
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    successMessageSetting: function () {
      if (!this.successMessageSetting) return;
      this.alertMsg(this.successMessageSetting, 'success');
      this.actionGetSettingByModule(this.setting?.moduleName);
    },
    errorMessageSetting: function () {
      if (!this.errorMessageSetting) return;
      this.alertMsg(this.errorMessageSetting, 'error');
    },
    maxNotif: function () {
      if (this.isError) return;
      this.formSetting = { ...this.maxNotif };
    },
    rangeTime: function () {
      if (this.isError) return;
      this.formSetting = { ...this.rangeTime };
    },
    textNotif: function () {
      if (this.isError) return;
      this.formSetting = { ...this.textNotif };
    },
    statusConnection: function () {
      if (this.isError) return;
      this.formSetting = { ...this.statusConnection };
    },
  },
  methods: {
    ...mapActions('receiver', [
      'getAllReceiver',
      'submitReceiver',
      'getReceiverById',
    ]),
    ...mapActions('setting', ['getSettingByModule', 'submitSetting']),
    ...mapActions('login', ['loginPassword']),
    ...mapActions('firebase', ['saveTokenFirebase']),
    loginPass() {
      Swal.fire({
        title: 'Masukan kata sandi pemilik sistem !',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async (pass) => {
          try {
            const logged = await this.loginPassword({ password: pass });
            return logged;
          } catch (error) {
            Swal.showValidationMessage(`
        Request failed: ${error}
      `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.value?.success) {
          this.isLogged = true;
          setTimeout(() => {
            this.getTokenFirebaseFCM();
          }, 3000);
          return;
        }
        if (!result.isConfirmed) {
          this.$router.push('/');
          return;
        }
        Swal.fire({
          title: 'Maaf!',
          text: 'Anda tidak memiliki akses',
          icon: 'error',
        });
        this.$router.push('/');
      });
    },
    actionGetReceiver() {
      if (!this.isLogged) return;
      const payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.getAllReceiver(payload);
    },
    actionGetSettingByModule(module) {
      if (!this.isLogged) return;
      const payload = {
        module,
      };
      this.getSettingByModule(payload);
    },

    async editReceiver(id) {
      this.$bvModal.show('modal-receiver');
      this.isAddReceiver = false;
      const receiver = await this.getReceiverById(id);
      this.form = { ...receiver };
    },

    actionSubmitReceiver() {
      if (!this.isLogged) return;
      if (!this.form.name || !this.form.phone) {
        this.alertMsg('harap lengkapi form terlebih dahulu', 'warning');
        return;
      }
      if (!this.form.phone?.toString().startsWith('62')) {
        this.alertMsg('nomor telepon harus diawali dengan 62', 'warning');
        return;
      }
      let payload = {
        name: this.form.name,
        phone: this.form.phone,
        isActive: this.form.isActive,
      };

      if (!this.isAddReceiver) {
        payload.id = this.form.id;
        payload.type = 'edit';
      }
      this.submitReceiver(payload);
      this.isAddReceiver = true;
      this.hideModal();
    },

    async actionUpdateSetting() {
      if (!this.isLogged) return;
      const payload = {
        ...this.formSetting,
      };
      this.submitSetting(payload);
      this.hideModal();
    },

    hideModal() {
      this.resetForm();
      this.$bvModal.hide('modal-receiver');
      this.$bvModal.hide('modal-maxNotif');
      this.$bvModal.hide('modal-rangeTime');
      this.$bvModal.hide('modal-textNotif');
      this.$bvModal.hide('modal-statusConnection');
    },

    resetForm() {
      this.form = {
        name: '',
        phone: '',
        isActive: true,
      };
      this.formMaxNotif = {
        setValue: '',
      };
    },

    validatePhoneNumber() {
      let phoneNumber = this.form.phone;
      if (phoneNumber.startsWith('0')) {
        phoneNumber = '62' + phoneNumber.substring(1);
      }
      this.form.phone = phoneNumber;
    },

    validateStatusConnection() {
      if (
        !['0', '1'].includes(this.formSetting.setValue?.toString()) &&
        this.formSetting.setValue
      ) {
        this.alertMsg('status koneksi hanya 0 dan 1', 'warning');
        this.formSetting.setValue = '';
        return;
      }
    },

    alertMsg(msg, icon) {
      Swal.fire({
        title: icon.toUpperCase(),
        text: msg,
        icon: icon,
      });
    },

    isMobile() {
      return screen.width <= 768;
    },

    getTokenFirebaseFCM() {
      // Initialize Firebase
      initializeApp(firebaseConfig);

      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        if (process.env.VUE_APP_ENVIRONMENT === 'development')
          console.log('Message received. ', payload);
      });
      const vapidKey =
        process.env.VUE_APP_ENVIRONMENT === 'development'
          ? 'BKx_mh7voL29KqKoxLIJaC1beFxjDOGWoCLwHsQBzSTU-s7G8Ymxy-hj7nXCmzuKvpHoj9kryGEzIsL73Ajb-CY'
          : 'BHibpAxjCylWyudlQ2IcfP3Vq3-mehfgk3cX3fKPJkIXncbPuT2dG-j6coLc5ghPC2ME_Q2kKgSko7uNxHrfFNo';
      getToken(messaging, {
        vapidKey,
      })
        .then((currentToken) => {
          if (currentToken) {
            if (this.isMobile())
              this.saveTokenFirebase({ token: currentToken });
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.',
            );
          }
        })
        .catch((err) => {
          console.log('ERROR GET TOKEN :  ', err);
        });
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

svg {
  font-family: 'Russo One', sans-serif;
  width: 100%;
  height: 100%;
}
svg text {
  animation: stroke 3s infinite alternate;
  stroke-width: 2;
  stroke: #231aa0;
  font-size: 25px;
}
@keyframes stroke {
  0% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgba(35, 26, 160, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgba(35, 26, 160, 1);
  }
  80% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgba(35, 26, 160, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(27, 86, 204, 1);
    stroke: rgba(35, 26, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.wrapper {
  background-color: #ffffff;
}
</style>
